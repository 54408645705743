@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.surroundingarea {
    background:transparent;

    padding-bottom: 20px;
    margin-top: 30px;
    @media (max-width: 768px) {
        
      }
      
    .container-surrounding {
        width: 100%;
        background: transparent;

        .judul-surrounding {
            width: 100%;
            display: flex;
            align-content: center;
            justify-content: center;


            @media (max-width: 768px) {

              }

            //margin-bottom: 2%;
            h1 {
                width: 100%;
    font-size: 40px;
    font-weight: 400;
    text-align: center;
    color: #9a7438;
    @media (max-width: 768px) {
      width: 100%;
      font-size: 30px;
      margin-top: -50px;
    }
            }
        }

        .container-card {
            .kartu {
                .kartugambar1 {
                    width: 60%;
                    //height: 700px;
                    margin: auto;
                    border-radius: 5px;

                    @media (max-width: 768px) {
                        width: 95%;
                        height: 75%;
                    }


                }

                .slick-next::before,
                .slick-prev::before {
                    font-family: "slick";
                    font-size: 30px;
                }

                .slick-next {
                    right:325px;

                    @media (max-width: 768px) {
                        right: 50px;
                    }
                }

                .slick-prev {
                    z-index: 1;
                    left: 325px;

                    @media (max-width: 768px) {
                        left: 50px;
                    }
                }

                .slick-dots {
                    position: absolute;
                    /* Make dots absolute for positioning within slides */
                    bottom: 10px;
                    /* Adjust vertical positioning */
                    left: 50%;
                    /* Center dots horizontally */
                    transform: translateX(-50%);
                    /* Center dots precisely */
                    z-index: 1;

                    /* Ensure dots are above image content */
                    li {
                        margin: 0;
                    }
                }
            }
        }
    }
    .container-wa{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        margin-top: 20px;
        margin-bottom: -50px;
        //margin-left: 75px;
        //margin-top: -10px;
        @media (max-width: 768px) {
          width: 100%;
          
        }
      .buttonpenawaran {
        color: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
        align-content: center;
        //margin-top: 30px;
        //margin-left: 15%;
        background-color: rgb(25, 47, 80);
        width: 25%;
        
        padding: 12px 30px;
        border: 1px solid white; /* Add this line for black border */
        border-color: white;
        border-radius: 20px;
        font-size: 16px;
        font-weight: light;
        cursor: pointer;
        display: inline-block;
        transition: background-color 0.3s ease, color 0.3s ease;
        @media (max-width: 768px) {
          justify-content: center;
          align-items: center;
          //margin-left: 15%;
          //margin-top: -30px;
          //margin-bottom: -20px;
          width: 80%;
  
        }
        &:hover {
          background-color: transparent;
          color: rgb(25, 47, 80);
          border-color: rgb(25, 47, 80);
        }
        
      }
    }
}