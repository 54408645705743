.promolagoon {
  width: 100%;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  margin-top: 20px;
  
  @media (max-width: 768px) {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      align-items: center;
  }
  .background {
      width: 90%;
      background-color: rgb(25, 47, 80);
      border-radius: 20px;
      padding-bottom: 25px;
      padding-top: 25px;
      @media (max-width: 768px) {
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          text-align: center;
          align-items: center;
      }
      
  }
  .judulpromo {
    width: 100%;
    font-weight: 600;
    font-size: 20px;
    margin-left: -10%;
    color: #ecf1ef;
    text-shadow: 2px 2px 4px rgb(133, 104, 46);
      @media (max-width: 768px) {
          width: 100%;
          font-size: 40px;
          color: #2f4d67;
      }
  }
  .container-promo {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      @media (max-width: 768px) {
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          //text-align: center;
          align-items: center;
      }

      
      

  }
  .promo-gambar {
      width: 45%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      @media (max-width: 768px) {
          width: 90%;
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;
      }
      img {
          width: 100%;
          border-radius: 20px;
          margin-left: 12%;
          @media (max-width: 768px) {
              width: 100%;
              margin-right: 12%;
          }
      }
  }
  .containercontent {
    order: 2;
    width: 55%;
    margin-left: 100px;
    text-align: left;
    justify-content: center;
    display: flex;
    flex-direction: column;
    @media (max-width: 768px) {
      margin-left: 25px;
      width: 90%;
      order: 2;
      margin-top: 15px;
    }
    .judul {
      width: 100%;
    font-weight: 600;
    font-size: 20px;
    color: #ecf1ef;
    text-shadow: 2px 2px 4px rgb(133, 104, 46);
    @media (max-width: 768px) {
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: -25px;
    }
    }
  
  .disclaimer {
    margin-top: 25px;
    font-size: 13px;
    color: white;
  }
  .pointpenawaran {
    flex-direction: row;
    font-size: 30px;
    @media (max-width: 768px) {
      font-size: 16px;
      width: 100%;
    }
    .penawaranpoin {
      margin-top: 0px;
      color: #e1c06c;
      @media (max-width: 768px) {
        width: 100%;
      }
    }
    
  }
  .buttonpenawaran {
    color: #00581c;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    margin-top: 30px;
    margin-left: 15%;
    background-color: white;
    width: 50%;
    padding: 15px 30px;
    border: 1px solid white; /* Add this line for black border */
    border-color: white;
    border-radius: 25px;
    font-size: 16px;
    font-weight: light;
    cursor: pointer;
    display: inline-block;
    transition: background-color 0.3s ease, color 0.3s ease;
    @media (max-width: 768px) {
      justify-content: center;
      align-items: center;
      margin-left: 15%;
      //margin-top: -30px;
      margin-bottom: -10px;
      width: 70%;
    }
    &:hover {
      background-color: transparent;
      color: white;
      border-color: white;
    }
  }
}

  }