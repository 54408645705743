@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.container-footer {
  width: 100%;
  display: flex;
  margin-bottom: -160px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: #091c34;
  box-shadow: 0 0 5px 0;
  padding-top: 25px;
  border-radius: 50px;
  @media (max-width: 768px) {
    justify-content: center;
    align-items: center;
    padding-top: 20px;
  }

  .container-atas {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    margin-bottom: -20px;
    //margin: 15px;

    @media (max-width: 768px) {
      flex-direction: column;
      width: 100%;
      margin: 0px;
      margin-bottom: -40px;
    }

    .container-logo {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      width: 80%;
      //padding: 20px;

      @media (max-width: 768px) {
        width: 100%;
        //margin-bottom: -20px;
      }

      .gambar-logo {
        width: 50%;

        @media (max-width: 768px) {
          width: 50%;
          padding-bottom: 40px;
          padding-top: 20px;
        }
      }
    }
    .menus {
      width: 50%;
      //padding-top: 10px;
      padding-bottom: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      text-align: center;
      margin-right: 100px;
      font-size: 20px;
      font-weight: 500;
      color: #c59953;
      @media (max-width: 768px) {
        flex-direction: column;
        background-color: #000000b0;
        justify-content: space-evenly;
        position: absolute;
        width: 100%;
        top: -230px;
        right: 0;
        text-align: center;
        padding-top: 0px;
        padding-bottom: 0px;
        //margin-right: 20px;
        border-radius: 0 0 10px 10px;
        z-index: -1;
      }
      &.active {
        top: 100%;
        margin-right: 0px;
        right: 0;
        transition: all 0.5s ease-in-out;
        z-index: -1;
      }
      ul {
        display: flex;
        flex-direction: column;

        padding-inline-start: 0px;
        @media (max-width: 768px) {
          margin: 5px;
        }
      }
      ul{
        :after {
          content: "";
          display: block;
          width: 0;
          height: 1px;
          background: #ffffff;
          transition: width 0.3s;
        }
        :hover::after {
          width: 100%;
          transition: width .3s;
        }
        li {
          display: inline-block;
          list-style-type: none;
          padding: 5px;
          text-align: center;
          @media (max-width: 768px) {
            padding: 10px;
            display: block;
          }      
          .link {
            text-decoration: none;
            color: white;
            font-family: "Poppins", sans-serif;
            font-weight: 500;
            text-align: center;
            font-size: 18px;
            @media (max-width: 768px) {
              color: #ffffff;
            }
          }
        }
      } 
    }

    .container-deskripsi {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;
      width: 80%;

      @media (max-width: 768px) {
        width: 100%;
        justify-content: center;
        align-items: center;
        margin-top: -30px;
        margin-bottom: 30px;
      }

      .alamat {
        width: 80%;
        text-align: center;
        justify-content: center;
        align-items: center;
        //margin-left: 20%;
        font-weight: 400;
        margin-bottom: 5%;
        font-family: "Poppins";
        font-size: 14px;

        @media (max-width: 768px) {
          justify-content: center;
          align-items: center;
          width: 100%;
          align-content: center;
          margin-left: 35%;
        }

        h1 {
          font-size: 40px;
          align-items: center;
          justify-content: center;
          font-family: "Poppins", serif;
          line-height: 1.2em;
          font-weight: 500;
          color: #c59953;;
          margin-bottom: -10px;

          @media (max-width: 768px) {
            font-size: 30px;
            margin-left: -35%;
          }
        }

        h2 {
          color: #f7f7f7;
          font-weight: 400;
          font-size: 25px;
          margin-bottom: -10px;
          font-family: "Poppins", serif;

          @media (max-width: 768px) {
            width: 100%;
            font-size: 24px;
            margin-left: -20%;
            text-align: center;
          }
        }

        h3 {
          color: #c2c2c2;
          font-weight: 400;
          font-size: 15px;
          margin-bottom: -10px;
          font-family: "Poppins", serif;

          @media (max-width: 768px) {
            width: 100%;
            font-size: 15px;
            margin-left: -15%;
          }
        }

        h4 {
          color: #c2c2c2;
          font-weight: 400;
          font-size: 15px;
          margin-bottom: -10px;
          margin-bottom: 10px;
          font-family: "Poppins", serif;

          @media (max-width: 768px) {
            font-size: 15px;
            margin-left: -30%;
          }
          
        }
        .footer-contact {
          width: 100%;
          @media (max-width: 768px) {
            //font-size: 15px;
            margin-left: -15%;
          }
        }

      }

      @media (max-width: 768px) {
        width: 80%;
      }

      .contact {
        font-weight: 400;
        font-family: "Poppins", serif;
        font-size: 14px;
        color: #1c244b;

        @media (max-width: 768px) {
          margin-top: 5px;
        }
      }

      //    .privasi{

      //  }
    }
  }
hr {
  width: 90%;
}
  .container-bawah {
    width: 100%;
    background: #091c34;
    //padding: 20px 0;
    text-align: center;
    display: flex;
    flex-direction: row;
    color: white;
    font-weight: lighter;
    font-family: "Roboto" sans-serifs;
    font-size: 18px;
    font-weight: 800px;
    margin-top: -9px;
    .rights {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      text-align: center;
      @media (max-width: 768px) {
        visibility: hidden;
        width: 0%;
      }
    }
    .judul-containerbawah {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      text-align: center;
    }
    .backtotop {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      text-align: center;
      @media (max-width: 768px) {
        visibility: hidden;
        width: 0%;
      }
      &.active {
        //top: 100%;
        margin-right: 0px;
        right: 0;
        transition: all 0.5s ease-in-out;
        z-index: -1;
      }
      ul {

        padding-inline-start: 0px;
        @media (max-width: 768px) {
          margin: 5px;
        }
      }
      ul{
        :after {
          content: "";
          display: block;
          width: 0;
          height: 1px;
          background: #ffffff;
          transition: width 0.3s;
        }
        :hover::after {
          width: 100%;
          transition: width .3s;
        }
        li {
          display: inline-block;
          list-style-type: none;
          padding: 5px;
          text-align: center;
          @media (max-width: 768px) {
            padding: 10px;
            display: block;
            visibility: hidden;
            width: 0%;
          }      
          .link {
            text-decoration: none;
            color: white;
            font-family: "Poppins", sans-serif;
            font-weight: 200;
            text-align: center;
            font-size: 18px;
            @media (max-width: 768px) {
              color: #ffffff;
              visibility: hidden;
              width: 0%;
            }
          }
        }
      } 
    }
  }
  
}